"use client";
import usePagination from "@/hooks/pagination";
import { formatDate } from "@utils/format";
import { removeBaseUrl } from "@utils/url";
import Link from "next/link";
import React from "react";
import LinkIcon from "@icons/link.svg";

type MetadataFields = {
  effective: string;
};

type Props = {
  initialData: PloneSearchResults<MetadataFields>;
  pageUrl: string;
};

function NewsListing(props: Props) {
  const { initialData, pageUrl } = props;
  const { data, Pagination } = usePagination({
    initialData,
    pageUrl,
  });

  return (
    <>
      <ul className="news-listing">
        {data?.items.map((item) => (
          <li key={item["@id"]} className="news-listing__item">
            <p className="news-listing__date">{formatDate(item.effective)}</p>
            <Link
              href={removeBaseUrl(item["@id"])}
              className="news-listing__link"
            >
              {item.title}
              <LinkIcon height={16} />
            </Link>
            {item?.description ? (
              <p className="news-listing__description">{item.description}</p>
            ) : null}
          </li>
        ))}
      </ul>
      <Pagination />
    </>
  );
}

export default NewsListing;
