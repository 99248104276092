"use client";
import Button from "@components/ui/Button/Button";
import LinkIcon from "@icons/link.svg";
import client, { hasError } from "@utils/client";
import { removeBaseUrl } from "@utils/url";
import Link from "next/link";
import { useState } from "react";

type MetadataFields = {
  organisation?: string;
  beratungsschwerpunkt: string[] | null;
};

type Props = {
  lookingFor: PloneVocabulary | null;
  partners: PloneSearchResults<MetadataFields>;
};

function Partners(props: Props) {
  const { lookingFor, partners } = props;
  const [topic, setTopic] = useState("");
  const [region, setRegion] = useState("");

  return (
    <>
      {lookingFor ? (
        <form
          className="form"
          onSubmit={(e) => {
            e.preventDefault();
            const formData = new FormData(e.currentTarget);
            setTopic(formData.get("topic")?.toString() ?? "");
          }}
          onReset={() => {
            setTopic("");
          }}
        >
          <div className="form__row">
            <div className="form__element">
              <select aria-label="Sie suchen" defaultValue="" name="topic">
                <option value="">Sie suchen</option>
                {lookingFor?.items.map((item) => (
                  <option key={item.token} value={item.token}>
                    {item.title}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="form__buttons">
            <Button type="submit" primary>
              Finden
            </Button>
            <Button type="reset">Filter zurücksetzen</Button>
          </div>
        </form>
      ) : null}
      <h3 className="partners__subtitle">Partner</h3>
      <ul className="partners__list">
        {partners?.items?.length
          ? partners?.items
              .filter((item) => {
                if (topic === "") return true;
                return item.beratungsschwerpunkt?.includes(topic);
              })
              ?.toSorted((a, b) =>
                (a.organisation ?? a.title).toLowerCase() <
                (b.organisation ?? b.title).toLowerCase()
                  ? -1
                  : 1,
              )
              .map((item) => (
                <li key={item["@id"]} className="partners__list-item">
                  <Link
                    href={removeBaseUrl(item["@id"])}
                    className="partners__link"
                  >
                    {item?.organisation ?? item.title} <LinkIcon height={12} />
                  </Link>
                </li>
              ))
          : null}
      </ul>
    </>
  );
}

export default Partners;
