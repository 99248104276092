"use client";
import React from "react";
import { useForm } from "../Form";

type Props = { name: string };

function FormErrorMessage(props: Props) {
  const { name } = props;
  const { errors } = useForm();

  if (!errors?.[name]) return null;

  return <div className="message message--error">{errors[name]}</div>;
}

export default FormErrorMessage;
