"use client";
import { useForm } from "@components/Form/Form";
import Button from "@components/ui/Button/Button";
import React, { PropsWithChildren } from "react";
import { useFormStatus } from "react-dom";

type Props = {};

function SubmitButton(props: PropsWithChildren<Props>) {
  const { pending } = useFormStatus();
  const { status } = useForm();

  return (
    <Button
      type="submit"
      primary
      aria-disabled={pending || status === "success"}
      disabled={pending || status === "success"}
    >
      {props.children}
    </Button>
  );
}

export default SubmitButton;
