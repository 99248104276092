import FileDownload from "@components/FileDownload";
import {
  isFileUrl,
  isPloneUrl,
  removeBaseUrl,
  replaceBaseUrl,
} from "@utils/url";
import clsx from "clsx";
import HtmlToReact from "html-to-react";
import Image from "next/image";
import Link from "next/link";

import RocketIcon from "@icons/rocket.svg";
import GearIcon from "@icons/gear.svg";
import ShoutoutIcon from "@icons/shoutout.svg";
import PinIcon from "@icons/pin.svg";
import ClockIcon from "@icons/clock.svg";
import RefreshVinegar from "@components/RefreshVinegar";

const dividers = [
  "rocket-divider",
  "big-rocket-divider",
  "shoutout-divider",
  "big-shoutout-divider",
  "pin-divider",
  "big-pin-divider",
  "gear-divider",
  "big-gear-divider",
  "clock-divider",
  "big-clock-divider",
];

const icons = [
  "icon-pin",
  "icon-rocket",
  "icon-gear",
  "icon-clock",
  "icon-shoutout",
];

const isValidNode = function() {
  return true;
};

// @ts-expect-error
const htmlParser = new HtmlToReact.Parser();

// @ts-expect-error
// Order matters. Instructions are processed in the order they're defined
const processNodeDefinitions = new HtmlToReact.ProcessNodeDefinitions();
const processingInstructions = [
  {
    // Custom <a> processing
    // @ts-expect-error
    shouldProcessNode: function(node) {
      return (
        node &&
        (node.name === "a" ||
          node.name === "img" ||
          node.name === "source" ||
          (node.name === "iframe" && node?.attribs?.src?.includes("youtube")) ||
          dividers.some((divider) => node.attribs?.class?.includes(divider)))
      );
    },
    // @ts-expect-error
    processNode: function(node, children) {
      for (const divider of dividers) {
        if (node.attribs.class?.startsWith(divider)) {
          return (
            <div className={divider} key={divider}>
              {divider.includes("rocket") ? (
                <RocketIcon width={32} height={32} strokeWidth={1} />
              ) : null}
              {divider.includes("gear") ? (
                <GearIcon width={32} height={32} strokeWidth={1} />
              ) : null}
              {divider.includes("shoutout") ? (
                <ShoutoutIcon width={32} height={32} strokeWidth={1} />
              ) : null}
              {divider.includes("pin") ? (
                <PinIcon width={32} height={32} strokeWidth={1} />
              ) : null}
              {divider.includes("clock") ? (
                <ClockIcon width={32} height={32} strokeWidth={1} />
              ) : null}
            </div>
          );
        }
      }

      if (node.name === "iframe") {
        // @ts-expect-error - custom attribute for consent-tool
        return <vine-gar {...node.attribs} type="iframe" purpose="youtube" />;
      }

      if (node.name === "img") {
        const {
          class: className,
          src,
          width,
          height,
          alt,
          title,
        } = node.attribs;

        if (src.includes("resolveuid")) return null;

        return (
          <Image
            alt={alt}
            src={replaceBaseUrl(src)}
            width={width}
            height={height}
            className={className}
            title={title}
          />
        );
      }

      if (node.name === "source") {
        const { srcset, ...rest } = node.attribs;
        return <source {...rest} srcSet={replaceBaseUrl(srcset)} />;
      }

      if (node.name === "a") {
        const { class: className, ...rest } = node.attribs;

        if (isPloneUrl(node.attribs.href) && isFileUrl(node.attribs.href)) {
          return (
            <Link
              {...rest}
              className={className}
              href={`${replaceBaseUrl(node.attribs.href)}/@@download`}
            >
              {children}
            </Link>
          );
        }

        return (
          <Link
            {...rest}
            className={className}
            href={removeBaseUrl(node.attribs.href)}
          >
            {children}
          </Link>
        );
      }
    },
  },
  {
    // Anything else
    // @ts-expect-error
    shouldProcessNode: function(node) {
      return true;
    },
    processNode: processNodeDefinitions.processDefaultNode,
  },
];

type Props = { html: string; className?: string };

function RawHtml(props: Props) {
  const { html, className } = props;

  const reactComponent = htmlParser.parseWithInstructions(
    html.replace(/style=".*?"/gm, ""),
    isValidNode,
    processingInstructions,
  );

  return (
    <>
      <div className={clsx("richtext", className)}>{reactComponent}</div>
      <RefreshVinegar />
    </>
  );
}

export default RawHtml;
