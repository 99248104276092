"use client";
import { BATCH_SIZE } from "@components/Pagination";
import Button from "@components/ui/Button";
import { removeBaseUrl } from "@utils/url";
import Link from "next/link";
import { usePathname, useRouter, useSearchParams } from "next/navigation";
import React, { useState } from "react";
import useSWR from "swr";
import LinkIcon from "@icons/link.svg";

type MetadataFields = {
  organisation?: string;
  beratungsschwerpunkt: string[] | null;
};

async function fetcher(url: string) {
  const res = await fetch(`${process.env.NEXT_PUBLIC_PLONE_API_URL}/${url}`);

  if (res.ok) {
    const data = (await res.json()) as PloneSearchResults<MetadataFields>;
    return data;
  }

  throw new Error("Something went wrong...");
}

type Props = {
  regions: PloneVocabulary | null;
  lookingFor: PloneVocabulary | null;
  initialData?: PloneSearchResults<MetadataFields>;
};

function SearchForm(props: Props) {
  const { regions, lookingFor, initialData } = props;

  const pathname = usePathname();
  const params = useSearchParams();
  const router = useRouter();

  const querystring = new URLSearchParams();
  querystring.append("partnerart", "branchennetzwerk");
  querystring.append("metadata_fields:list", "organisation");

  const topic = params.get("topic");
  if (topic) querystring.append("Subject", topic.toString());

  const region = params.get("region");
  if (region) querystring.append("region", region.toString());

  const { data, isLoading } = useSWR(
    `@search?${querystring.toString()}`,
    fetcher,
    {
      fallbackData: initialData,
      keepPreviousData: true,
    },
  );

  return (
    <>
      <form
        className="form"
        onSubmit={(e) => {
          e.preventDefault();
          const formData = new FormData(e.currentTarget);
          const qs = new URLSearchParams();

          const topic = formData.get("topic");
          if (topic) qs.append("topic", topic.toString());

          const region = formData.get("region");
          if (region) qs.append("region", region.toString());

          router.replace(`${pathname}/?${qs.toString()}`, { scroll: false });
        }}
        onReset={() => {
          router.replace(pathname, { scroll: false });
        }}
      >
        <div className="form__row">
          <div className="form__element">
            <select
              aria-label="Sie suchen"
              defaultValue={topic ?? ""}
              name="topic"
            >
              <option value="">Sie suchen</option>
              {lookingFor?.items.map((item) => (
                <option key={item.token} value={item.token}>
                  {item.title}
                </option>
              ))}
            </select>
          </div>
          {regions ? (
            <div className="form__element">
              <select
                aria-label="Sie suchen"
                defaultValue={region ?? ""}
                name="region"
              >
                <option value="">In welcher Region</option>
                {regions?.items.map((item) => (
                  <option key={item.token} value={item.token}>
                    {item.title}
                  </option>
                ))}
              </select>
            </div>
          ) : null}
        </div>
        <div className="form__buttons">
          <Button type="submit" primary disabled={isLoading}>
            Finden
          </Button>
          <Button type="reset">Filter zurücksetzen</Button>
        </div>
      </form>
      <h3 className="partners__subtitle">Partner</h3>
      <ul className="partners__list">
        {data?.items?.length ? (
          data?.items
            ?.toSorted((a, b) =>
              (a.organisation ?? a.title).toLowerCase() <
              (b.organisation ?? b.title).toLowerCase()
                ? -1
                : 1,
            )
            .map((item) => (
              <li key={item["@id"]} className="partners__list-item">
                <Link
                  href={removeBaseUrl(item["@id"])}
                  className="partners__link"
                >
                  {item?.organisation ?? item.title} <LinkIcon height={12} />
                </Link>
              </li>
            ))
        ) : (
          <p>Für die ausgewählten Filter sind keine Ergebnisse vorhanden.</p>
        )}
      </ul>
    </>
  );
}

export default SearchForm;
