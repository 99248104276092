import { ChevronLeftIcon, ChevronRightIcon } from "@radix-ui/react-icons";
import { removeBaseUrl } from "@utils/url";
import { useRouter, useSearchParams } from "next/navigation";
import useSWR from "swr";
import Pagination, { BATCH_SIZE } from "@components/Pagination";
import { useState } from "react";

type PaginationParams<T = unknown> = {
  pageUrl?: string;
  initialData: T;
  onPageChange?: () => void;
};

function usePagination<T extends PloneSearchResults>(
  params: PaginationParams<T>,
) {
  const { initialData, onPageChange, pageUrl } = params;
  const [filters, setFilters] = useState<Record<string, string>>({});
  const searchParams = useSearchParams();
  const router = useRouter();

  const pagesCount = Math.ceil(initialData.items_total / BATCH_SIZE);

  const { data, error } = useSWR<T>(
    () => {
      const url = new URL(
        initialData?.["batching"]?.["@id"] ?? initialData["@id"],
      );
      url.searchParams.set("b_size", BATCH_SIZE.toString());
      url.searchParams.set(
        "b_start",
        `${Number(searchParams.get("page")) * BATCH_SIZE}`,
      );

      Object.entries(filters).forEach(([filter, value]) => {
        if (!value) return;
        url.searchParams.set(filter, value);
      });

      return removeBaseUrl(url.toString());
    },
    async (url) => {
      const res = await fetch(`${process.env.NEXT_PUBLIC_PLONE_API_URL}${url}`);

      if (res.ok) {
        const data = (await res.json()) as T;
        return data;
      }

      throw res;
    },
    {
      fallbackData: initialData,
      keepPreviousData: true,
    },
  );

  function PagePagination() {
    return (
      <Pagination
        itemsTotal={data?.items_total ?? 0}
        onPrevPage={() => {
          router.replace(
            `${
              pageUrl
                ? removeBaseUrl(pageUrl)
                : removeBaseUrl(initialData["@id"])
            }?page=${Math.max(Number(searchParams.get("page")) - 1, 0)}`,
          );
          onPageChange?.();
        }}
        onNextPage={() => {
          router.replace(
            `${
              pageUrl
                ? removeBaseUrl(pageUrl)
                : removeBaseUrl(initialData["@id"])
            }?page=${Math.min(
              Number(searchParams.get("page")) + 1,
              pagesCount,
            )}`,
          );
          onPageChange?.();
        }}
      />
    );
  }

  return { data, Pagination: PagePagination, setFilters };
}

export default usePagination;
