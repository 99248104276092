"use client";
import React, {
  FormHTMLAttributes,
  PropsWithChildren,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";
import { useFormState } from "react-dom";

type FormState = "error" | "success" | "idle";

const FormContext = createContext<{
  errors?: Record<string, string>;
  status: FormState;
}>({ status: "idle" });

type Props = {
  formAction: any;
  onSuccess?: () => void;
} & FormHTMLAttributes<HTMLFormElement>;

const Form = React.forwardRef<HTMLFormElement, PropsWithChildren<Props>>(
  function (props, ref) {
    const { formAction: action, onSuccess, children, ...rest } = props;
    const [state, formAction] = useFormState<FormState>(action, "idle");
    const [errors, setErrors] = useState<Record<string, string> | undefined>(
      undefined,
    );

    useEffect(() => {
      if (state === "success") {
        onSuccess?.();
      }
    }, [state, onSuccess]);

    return (
      <FormContext.Provider value={{ errors, status: state }}>
        <form
          className="form"
          {...rest}
          action={formAction}
          ref={ref}
          autoComplete="off"
        >
          {children}
          <div
            style={{ overflow: "hidden", width: 0, height: 0 }}
            aria-hidden="true"
          >
            <label htmlFor="decoy-comments">
              Leave this blank if you&apos;re a human
            </label>
            <textarea
              rows={1}
              name="decoy-comments"
              id="decoy-comments"
              tabIndex={-1}
            />
          </div>
          <p className="form__required-fields">
            <sup>*</sup> Pflichtfelder
          </p>
        </form>
      </FormContext.Provider>
    );
  },
);

Form.displayName = "Form";

export function useForm() {
  const context = useContext(FormContext);

  if (!context)
    throw new Error("use Form needs to be used inside a FormContext.");

  return context;
}

export default Form;
