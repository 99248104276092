import clsx from "clsx";
import React, {
  ButtonHTMLAttributes,
  PropsWithChildren,
  forwardRef,
} from "react";

type Props = {
  primary?: boolean;
  secondary?: boolean;
} & ButtonHTMLAttributes<HTMLButtonElement>;

const Button = forwardRef<HTMLButtonElement, Props>(function (props, ref) {
  const { primary, secondary, ...rest } = props;
  return (
    <button
      {...rest}
      className={clsx(
        "button",
        { "button--primary": primary, "button--secondary": secondary },
        props.className,
      )}
    />
  );
});

Button.displayName = "Button";

export default Button;
