"use client";
import usePagination from "@/hooks/pagination";
import RelatedItem from "@components/RelatedItem";
import LinkIcon from "@icons/link.svg";
import { removeBaseUrl, replaceBaseUrl } from "@utils/url";
import Image from "next/image";
import Link from "next/link";
import { usePathname } from "next/navigation";
import { useEffect, useRef } from "react";

type MetadataFields = {
  image_caption?: string;
};

type Props = { initialData: PloneSearchResults<MetadataFields> };

function SuccessStories(props: Props) {
  const { initialData } = props;
  const pathname = usePathname();
  const { data, Pagination } = usePagination({
    initialData,
    onPageChange: () => ref.current?.scrollIntoView(),
    pageUrl: pathname,
  });
  const ref = useRef<HTMLDivElement>(null);

  return (
    <>
      <section
        aria-label="Erfolgsgeschichten"
        className="success-stories"
        ref={ref}
      >
        {data?.items.map((item) => (
          <RelatedItem
            key={item["@id"]}
            title={item.title}
            description={item.description}
            url={item["@id"]}
            image={
              item.image_scales?.vorschaubild?.[0]?.download
                ? replaceBaseUrl(
                    `${item["@id"]}/${item.image_scales?.vorschaubild?.[0]?.download}`,
                  )
                : undefined
            }
            withDescription
          />
        ))}
      </section>
      <Pagination />
    </>
  );
}

export default SuccessStories;
