"use client";
import { ChevronLeftIcon, ChevronRightIcon } from "@radix-ui/react-icons";
import { removeBaseUrl } from "@utils/url";
import { useSearchParams } from "next/navigation";
import React from "react";

// default batch size for plone is 25
export const BATCH_SIZE = 25;

type Props = {
  itemsTotal: number;
  onNextPage: () => void;
  onPrevPage: () => void;
};

function Pagination(props: Props) {
  const { itemsTotal, onPrevPage, onNextPage } = props;
  const searchParams = useSearchParams();

  const pagesCount = Math.ceil(itemsTotal / BATCH_SIZE);

  if (pagesCount <= 1) return null;

  return (
    <div className="pagination" role="nav" aria-label="Paginierung">
      <button
        type="button"
        aria-label={`Gehe zu Seite ${Number(
          searchParams.get("page"),
        )} von ${pagesCount}`}
        className="pagination__button"
        onClick={onPrevPage}
        disabled={Number(searchParams.get("page")) === 0}
      >
        <ChevronLeftIcon width={32} height={32} />
      </button>
      <span className="pagination__text">
        Seite {Number(searchParams.get("page")) + 1} von {pagesCount}
      </span>
      <button
        type="button"
        aria-label={`Gehe zu Seite ${
          Number(searchParams.get("page")) + 1
        } von ${pagesCount}`}
        className="pagination__button"
        onClick={onNextPage}
        disabled={pagesCount - 1 === Number(searchParams.get("page"))}
      >
        <ChevronRightIcon width={32} height={32} />
      </button>
    </div>
  );
}

export default Pagination;
