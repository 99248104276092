import LinkIcon from "@icons/link.svg";
import { removeBaseUrl, replaceBaseUrl } from "@utils/url";
import clsx from "clsx";
import Image from "next/image";
import Link from "next/link";

type Props = {
  title: string;
  description?: string | null;
  image?: string | null;
  imageCaption?: string | null;
  withDescription?: boolean;
  url: string;
};
// `${replaceBaseUrl(url)}/@@images/vorschaubild/preview`
function RelatedItem(props: Props) {
  const { title, url, imageCaption, image, description, withDescription } =
    props;

  return (
    <div
      className={clsx("related-item", {
        "related-item--with-description": withDescription,
      })}
    >
      <Link
        href={removeBaseUrl(url)}
        className={clsx("related-item__story", {
          "related-item__story--without-image": !image,
        })}
      >
        <figure className="related-item__figure">
          {image ? (
            <Image
              className="related-item__image"
              alt=""
              src={image}
              width={400}
              height={300}
              sizes="(max-width: 768px) 100vw, 50vw"
            />
          ) : null}
          {imageCaption ? <figcaption>{imageCaption}</figcaption> : null}
        </figure>
        <div className="related-item__content">
          <h2 className="related-item__title">
            {title} {!withDescription ? <LinkIcon height={12} /> : null}
          </h2>
          {withDescription ? (
            <p className="related-item__description">
              {description} <LinkIcon height={12} />
            </p>
          ) : null}
        </div>
      </Link>
    </div>
  );
}

export default RelatedItem;
