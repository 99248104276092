"use client";
import RawHtml from "@components/RawHtml";
import * as Acc from "@radix-ui/react-accordion";
import { ChevronDownIcon } from "@radix-ui/react-icons";
import { ReactNode, useState } from "react";

type Props = { items: { title: string; content: ReactNode }[] };

function Accordion(props: Props) {
  const { items } = props;

  const [open, setOpen] = useState(false);

  return (
    <Acc.Root type="single" collapsible className="accordion">
      {items.map((item, index) => (
        <Acc.Item
          key={item.title}
          value={index.toString()}
          className="accordion__item"
        >
          <Acc.Trigger className="accordion__trigger">
            {item.title} <ChevronDownIcon width={28} height={28} />
          </Acc.Trigger>
          <Acc.Content className="accordion__content" forceMount>
            {typeof item.content === "string" ? (
              <RawHtml html={item.content} />
            ) : (
              item.content
            )}
          </Acc.Content>
        </Acc.Item>
      ))}
    </Acc.Root>
  );
}

export default Accordion;
