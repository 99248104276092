"use client";
import React from "react";
import { useForm } from "../Form";

type Props<T extends string> = {
  successMessage?: string;
  errorMessage?: string;
  customErrors?: Record<T, string>;
};

function FormMessage<T extends string>(
  props: Props<Exclude<T, "success" | "error">>,
) {
  const { successMessage, errorMessage, customErrors } = props;
  const { status } = useForm();

  if (status === "idle") return null;

  if (status === "error") {
    return (
      <div className="message message--error">
        {errorMessage ??
          "Leider ist etwas schief gegangen. Bitte überprüfen Sie Ihre Eingaben und versuchen es erneut."}
      </div>
    );
  }
  if (status === "success") {
    return (
      <div className="message message--success">{successMessage ?? ""}</div>
    );
  }

  if (customErrors?.[status]) {
    return <div className="message message--error">{customErrors[status]}</div>;
  }

  return (
    <div className="message message--error">
      Leider ist etwas schief gegangen. Bitte überprüfen Sie Ihre Eingaben und
      versuchen es erneut.
    </div>
  );
}

export default FormMessage;
