"use client";
import LoadingSpinner from "@components/LoadingSpinner";
import { replaceBaseUrl } from "@utils/url";
import { useSession } from "next-auth/react";
import React, { PropsWithChildren, useState } from "react";

type Props = { href: string; title: string };

function FileDownload(props: PropsWithChildren<Props>) {
  const { children, href, title } = props;
  const session = useSession();
  const [status, setStatus] = useState("idle");

  return (
    <button
      className="file-download"
      title={title}
      disabled={status === "loading"}
      onClick={async () => {
        try {
          setStatus("loading");
          const res = await fetch(`${replaceBaseUrl(href)}/@@download`, {
            headers: {
              Authorization: `Bearer ${session?.data?.token}`,
            },
          });

          const file = await res.blob();
          const url = window.URL.createObjectURL(file);

          const anchor = document.createElement("a");
          anchor.download = title;
          anchor.href = url;
          anchor.click();
          // window.open(url, "_blank");
          window.URL.revokeObjectURL(url);
          setStatus("idle");
        } catch (error) {
          setStatus("error");
          console.error(error);
        }
      }}
    >
      {children}
      {status === "loading" ? (
        <div className="file-download__loading-overlay">
          <LoadingSpinner />
          <p>Bereite Download vor...</p>
        </div>
      ) : null}
    </button>
  );
}

export default FileDownload;
