"use client";
import usePagination from "@/hooks/pagination";
import Button from "@components/ui/Button/Button";
import LinkIcon from "@icons/link.svg";
import { formatDate, formatTime } from "@utils/format";
import { removeBaseUrl } from "@utils/url";
import Link from "next/link";
import { usePathname } from "next/navigation";
import { useRef } from "react";

type MetadataFields = { start: string; end: string; location: string };

type Props = {
  initialData: PloneSearchResults<MetadataFields>;
  regions: PloneVocabulary | null;
  keywords: PloneVocabulary | null;
};

function Events(props: Props) {
  const { initialData, regions, keywords } = props;
  const pathname = usePathname();
  const { data, Pagination, setFilters } = usePagination({
    initialData,
    onPageChange: () => ref.current?.scrollIntoView(),
    pageUrl: pathname,
  });
  const ref = useRef<HTMLDivElement>(null);

  return (
    <div className="events" ref={ref}>
      <form
        className="form"
        onSubmit={(e) => {
          e.preventDefault();
          const formData = new FormData(e.currentTarget);

          setFilters(Object.fromEntries(formData) as Record<string, string>);
        }}
        onReset={() => {
          setFilters({});
        }}
      >
        <div className="form__row">
          {regions ? (
            <div className="form__element">
              <select aria-label="Region" defaultValue="" name="region">
                <option value="">Region</option>
                {regions.items.map((region) => (
                  <option key={region.token} value={region.token}>
                    {region.title}
                  </option>
                ))}
              </select>
            </div>
          ) : null}
          {keywords ? (
            <div className="form__element">
              <select aria-label="Thema" defaultValue="" name="Subject">
                <option value="">Thema</option>
                {keywords.items.map((keyword) => (
                  <option key={keyword.token} value={keyword.token}>
                    {keyword.title}
                  </option>
                ))}
              </select>
            </div>
          ) : null}
        </div>
        <div className="form__buttons">
          <Button primary type="submit">
            filtern
          </Button>
          <Button type="reset">Zurücksetzen</Button>
        </div>
      </form>
      <ul className="events__list">
        {data?.items.map((item) => (
          <li key={item["@id"]} className="event">
            <p className="event__date">
              {formatDate(item.start)} / {formatTime(item.start)} -{" "}
              {formatTime(item.end)}
            </p>
            <p className="event__title">
              <Link className="event__link" href={removeBaseUrl(item["@id"])}>
                {item.title} <LinkIcon height={16} />
              </Link>
            </p>
            <p className="event__location">{item.location}</p>
          </li>
        ))}
      </ul>
      <Pagination />
    </div>
  );
}

export default Events;
