"use client";
import React, { useMemo } from "react";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import RawHtml from "@components/RawHtml";
import L from "leaflet";

import "leaflet/dist/leaflet.css";

type Props = {
  latitude: number;
  longitude: number;
  markerTitle: string;
};

function Map(props: Props) {
  const { latitude, longitude, markerTitle } = props;

  const icon = useMemo(
    () =>
      L.icon({
        iconUrl: "/marker-icon.png",
        iconRetinaUrl: "/marker-icon-2x.png",
        shadowUrl: "/marker-shadow.png",
        iconSize: [25, 41],
        iconAnchor: [12, 41],
        popupAnchor: [1, -34],
        tooltipAnchor: [16, -28],
        shadowSize: [41, 41],
      }),
    [],
  );

  return (
    <div className="map">
      <MapContainer
        center={[latitude, longitude]}
        zoom={17}
        scrollWheelZoom={true}
        className="map__container"
      >
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url={`${
            process.env.BASE_URL ?? process.env.NEXT_PUBLIC_BASE_URL
          }/tile/{z}/{x}/{y}.png`}
        />
        <Marker position={[latitude, longitude]} key={markerTitle} icon={icon}>
          <Popup>
            <p>
              <strong>{markerTitle}</strong>
            </p>
          </Popup>
        </Marker>
      </MapContainer>
    </div>
  );
}

export default Map;
